.section.callback {
  position: relative;
  top: -9px;
  padding: 40px 0 120px;
  background-color: var(--light-black);
  background-image: url(../images/callback/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/callback/bg@2x.png);
  }
}

.faq-box {
  margin-bottom: 50px;
}

.faq__title {
  margin-bottom: 50px;
  max-width: 350px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1;
  color: var(--black);
}

.faq__list-item {
  padding: 26px 0;
  height: 75px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  transition: var(--transition);
}

.faq__question-box {
  display: flex;
  align-items: flex-start;

  .order {
    margin-right: 38px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.37;
    color: var(--purple);
  }

  .question,
  .btn {
    background-color: transparent;
    border: none;
  }

  .question {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 22px;
    line-height: 1;
    color: var(--black);
    transition: var(--transition);

    &:hover,
    &:focus {
      color: var(--purple);
    }
  }

  .btn.plus,
  .btn.minus {
    flex-grow: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    min-width: 21px;
    min-height: 21px;

    .icon {
      width: 21px;
      height: 21px;
    }

    &.is-show {
      display: flex;
    }
  }
}

.faq__answer-box {
  .answer {
    animation-duration: 1s;
  }
}

.callback__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  color: var(--black);
}

.callback__text {
  margin: 0 0 70px auto;
  padding-left: 50px;
  max-width: 360px;

  .bg-text {
    font-weight: 600;
    color: var(--black);
    background-image: linear-gradient(
      90deg,
      rgba(149, 255, 207, 1) 0%,
      rgba(149, 255, 207, 1) 50%,
      rgba(149, 255, 207, 1) 100%
    );
  }
}

@include for-size(tablet) {
  .callback__text {
    margin: 0 0 70px 300px;
  }
}

@include for-size(desktop-s) {
  .section.callback {
    .desktop-flex {
      display: flex;
      gap: 60px;
    }
  }

  .faq-box,
  .callback__box {
    flex-basis: calc((100% - 60px * 1) / 2);
  }

  .callback__box {
    margin-top: 40px;
  }

  .callback__text {
    margin: 0 0 70px 0;
  }
}

@include for-size(desktop-m) {
  .section.callback {
    padding: 85px 0 237px;
    overflow: hidden;

    .desktop-flex {
      gap: 92px;
    }
  }

  .faq__title {
    max-width: 370px;
    font-size: 64px;
  }

  .faq-box,
  .callback__box {
    flex-basis: unset;
  }

  .faq-box {
    max-width: 505px;
  }

  .callback__title {
    margin: 0 0 24px 0;
  }

  .callback__text {
    padding-left: 32px;
    max-width: 450px;
    font-size: 28px;
    line-height: 1.07;

    &.text-arrow.black::before {
      left: 3px;
    }
  }
}
