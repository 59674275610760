.book-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 27px 58px 19px 56px;
  width: 130px;
  height: 130px;
  font-family: var(--second-text);
  font-weight: 900;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--black);
  text-align: left;
  background-color: var(--light-green);
  border: none;
  border-radius: 50%;
  -webkit-box-shadow: -15px 25px 100px -5px rgba(149, 255, 207, 1);
  -moz-box-shadow: -15px 25px 100px -5px rgba(149, 255, 207, 1);
  box-shadow: -15px 25px 100px -5px rgba(149, 255, 207, 1);
  transition: all 500ms linear;

  &:hover,
  &:focus {
    transform: scale(1.2) !important;
  }
}

@include for-size(mobile) {
  .book-btn {
    width: 150px;
    height: 150px;
    font-size: 14px;
  }
}

@include for-size(tablet) {
  .book-btn {
    width: 150px;
    height: 150px;
    font-size: 14px;
  }
}

@include for-size(desktop-s) {
  .book-btn {
    font-size: 12px;
  }
}
