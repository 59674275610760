.section.reviews {
  position: relative;
  top: -6px;
  padding: 60px 0 130px 0;
  background-color: var(--pink);
  background-image: url(../images/reviews/bg.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0em center;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/reviews/bg@2x.png);
  }
}

.reviews__title {
  margin-bottom: 70px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.06;
  color: var(--white);
}

.reviews__list {
  margin-bottom: 50px;
  height: 525px;
  transition: var(--transition);
  overflow: hidden;

  &.is-show {
    height: 1440px;
  }
}
.reviews__list-item {
  margin: 0 auto;
  width: 270px;
  border-radius: 10px;
  overflow: hidden;
  animation-duration: 1s;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.reviews__show-btn {
  margin: 0 auto;
  padding: 22px 14px 19px 25px;
  color: var(--white);
  text-shadow: none;
  background-color: var(--blue);
  -webkit-box-shadow: -15px 25px 100px -5px #b0bcff;
  -moz-box-shadow: -15px 25px 100px -5px #b0bcff;
  box-shadow: -15px 25px 100px -5px #b0bcff;

  &.is-show {
    padding: 22px 14px 19px 15px;
  }
}

@include for-size(tablet) {
  .reviews__list {
    height: 405px;
    column-count: 2;
    column-gap: 0;

    &.is-show {
      height: 1073px;
    }
  }
}

@include for-size(desktop-s) {
  .section.reviews {
    padding: 90px 0 140px;
    background-size: 100% 100%;
  }

  .reviews__title {
    margin-bottom: 70px;
    font-size: 64px;
  }

  .reviews__list {
    margin-bottom: 60px;
    height: 415;
    column-count: 3;

    &.is-show {
      height: 590px;
    }
  }
}

@include for-size(desktop-m) {
  .reviews__list {
    margin-bottom: 20px;
    height: 370px;
    column-count: 4;

    &.is-show {
      height: 435px;
    }
  }
}
