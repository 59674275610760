.header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 0 0 0;
  width: 100%;
  z-index: 5;
}
.header__menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 48px;
    height: 20px;
  }
}
.header__menu-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--light-black);
  transform: translateX(-110%);
  transition: var(--transition);
  z-index: 10;

  &.is-show {
    transform: translate(0);
  }
}
.nav__list {
  margin-bottom: 20px;
}
.nav__list-item {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.nav__link {
  display: block;
  padding: 5px;
  font-family: var(--second-family);
  font-weight: 300;
  text-align: center;
  color: var(--gray);
}
.header__list-social {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}
.header__social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .icon {
    fill: var(--light-green);
    width: 26px;
    height: 26px;
  }
}
.header__mobile-tel {
  display: block;
  margin-bottom: 15px;
  color: var(--gray);
}
.header__cb-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: var(--gray);
  background-color: transparent;
  border: 1px solid var(--gray);
  border-radius: 50px;
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--light-black);
    background-color: var(--gray);
  }

  &.desktop {
    display: none;
  }
}
.header__close-menu-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: transparent;
  border: none;

  .icon {
    width: 32px;
    height: 32px;
    fill: var(--white);
  }
}
.header__tel {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  color: var(--black);
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--white);
  }
}
.header__burger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: transparent;
  border: none;

  .icon {
    width: 31px;
    height: 20px;
  }
}

@include for-size(desktop-s) {
  .header {
    padding: 12px 0 0 0;
  }

  .header__menu-container {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: auto;
    background-color: transparent;
    transform: translateX(0);
    transition: var(--transition);
    z-index: 10;
  }

  .nav__list {
    display: flex;
    margin: 0;
  }

  .nav__list-item {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .nav__link {
    display: block;
    padding: 5px;
    font-size: 14px;
    line-height: 1.2;
    color: var(--second-text);
    transition: var(--transition);

    &:hover,
    &:focus {
      color: var(--white);
    }
  }

  .header__list-social {
    margin-bottom: 0;
    gap: 0;
  }

  .header__social-link {
    padding: 5px 3px;
    transition: var(--transition);
    &:hover,
    &:focus {
      .icon {
        transition: var(--transition);
        fill: var(--light-black);
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .header__contanct-box-desktop {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header__cb-btn {
    color: var(--black);
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 50px;
    transition: var(--transition);

    &:hover,
    &:focus {
      color: var(--light-black);
      background-color: var(--white);
    }

    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }

  .header__burger,
  .header__close-menu-btn,
  .header__mobile-tel {
    display: none;
  }
}

@include for-size(desktop-m) {
  .header__menu-box {
    justify-content: flex-start;
  }

  .header__logo {
    margin-right: 25px;

    .icon {
      width: 71px;
      height: 30px;
    }
  }

  .nav__list {
    gap: 5px;
    margin-right: 26px;
  }

  .header__contanct-box-desktop {
    gap: 63px;
    margin-left: auto;
  }

  .header__tel {
    font-size: 16px;
  }
}
