.section.for-parents {
  position: relative;
  top: -5px;
  padding: 60px 0 200px 0;
  background-color: var(--light-black);
  background-image: url(../images/for-parents/bg.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0em center;
  overflow: hidden;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/for-parents/bg@2x.png);
  }
}

.for-parents__title {
  margin-bottom: 50px;
  max-width: 380px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.06;
  color: var(--black);
}

.for-parents__content-box {
  &:nth-child(1) {
    .for-parents__img-box {
      padding-top: 140px;
      z-index: 3;

      .spin-sun {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 359px;
        height: 369px;
        background-image: url(../images/icons/sun.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: -1;

        @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
          background-image: url(../images/icons/sun@2x.png);
        }
      }
    }
  }

  &:nth-child(3) {
    .box {
      margin-bottom: 40px;
    }

    .title {
      margin-bottom: 22px;
      font-weight: 600;
      font-size: 28px;
      line-height: 79%;
      color: var(--black);
    }

    .text {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    .for-parents__img-box {
      z-index: 3;
      &::after {
        content: '';
        position: absolute;
        bottom: -145px;
        right: -125px;
        display: block;
        width: 365px;
        height: 209px;
        background-image: url(../images/for-parents/w-white.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: -1;

        @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
          background-image: url(../images/for-parents/w-white@2x.png);
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 60px;
  }
}

.for-parents__text-list {
  margin-bottom: 40px;
}

.for-parents__text-list-item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:last-child {
    .title,
    .text {
      text-align: right;
    }
  }

  .title {
    margin-bottom: 22px;
    font-weight: 600;
    font-size: 28px;
    line-height: 79%;
    color: var(--black);
  }
  .text {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}

.for-parents__img-box {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include for-size(mobile) {
  .for-parents__content-box {
    .text {
      max-width: 400px;
    }
  }

  .for-parents__text-list-item {
    .text {
      max-width: 400px;
    }
    &:last-child {
      .text {
        margin-left: auto;
      }
    }
  }
}

@include for-size(tablet) {
  .for-parents__content-box {
    &:nth-child(1) {
      .for-parents__img-box {
        padding-top: 235px;
        margin: 0 auto;
        width: 570px;

        .spin-sun {
          top: 0;
          width: 490px;
          height: 520px;
        }
      }
    }

    &:nth-child(2) {
      .for-parents__img-box {
        width: 530px;

        &::after {
          content: '';
          position: absolute;
          bottom: -60px;
          right: -128px;
          display: block;
          width: 220px;
          height: 126px;
          background-image: url(../images/for-parents/w-purple.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;

          @media screen and (min-device-pixel-ratio: 2),
            screen and (min-resolution: 192dpi),
            screen and (min-resolution: 2dppx) {
            background-image: url(../images/for-parents/w-purple@2x.png);
          }
        }
      }
    }

    &:nth-child(3) {
      .for-parents__img-box {
        margin-left: auto;
        width: 530px;

        &::after {
          bottom: -110px;
          right: unset;
          left: -180px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    .text {
      max-width: 500px;
    }
  }

  .for-parents__text-list-item {
    .text {
      max-width: 500px;
    }
  }
}

@include for-size(desktop-s) {
  .for-parents__title {
    max-width: 460px;
    margin-bottom: 80px;
    font-size: 64px;
  }

  .section.for-parents {
    position: relative;
  }

  .for-parents__content-box {
    &:nth-child(1) {
      display: flex;
      gap: 50px;
      align-items: center;

      .for-parents__img-box {
        padding-top: 0;

        .spin-sun {
          top: -225px;
          width: 359px;
          height: 369px;
        }
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: row-reverse;
      gap: 60px;
      margin-bottom: 140px;

      .for-parents__img-box {
        margin-top: 150px;
      }
    }

    &:nth-child(3) {
      display: flex;
      gap: 50px;
    }
  }

  .for-parents__text-list {
    margin-bottom: 0;
  }

  .for-parents__text-list-item {
    &:last-child {
      .title,
      .text {
        text-align: left;
        margin-left: 0;
      }
    }
  }
}

@include for-size(desktop-m) {
  .section.for-parents {
    position: relative;
    padding: 148px 0 254px 0;
  }

  .for-parents__title {
    margin: 0;
    max-width: 580px;
  }

  .for-parents__content-box {
    &:nth-child(1) {
      align-items: flex-end;
      gap: 133px;
      margin-bottom: 215px;

      .for-parents__img-box {
        bottom: 12px;
        margin-bottom: 10px;
        width: 470px;
        height: 420px;

        .spin-sun {
          top: -234px;
          left: 47%;
        }
      }
    }

    &:nth-child(2) {
      margin-bottom: 206px;

      .for-parents__img-box {
        position: absolute;
        left: -200px;
        top: 925px;
        margin: 0;
        width: 775px;
        height: 532px;

        &::after {
          bottom: -115px;
          right: -246px;
          width: 425px;
          height: 243px;
        }
      }
    }

    &:nth-child(3) {
      gap: 130px;

      .box {
        margin: 28px 0 0 200px;
        max-width: 270px;

        .title {
          max-width: 235px;
        }

        .text {
          max-width: unset;
        }
      }

      .for-parents__img-box {
        margin: 0;
        width: 401px;
        height: 320px;

        &::after {
          bottom: -72px;
          right: -215px;
          left: unset;
          width: 365px;
          height: 209px;
        }
      }
    }
  }

  .for-parents__text-list {
    display: flex;
    gap: 30px;
    margin-bottom: 26px;
  }

  .for-parents__text-list-item {
    max-width: 270px;

    .text {
      max-width: unset;
    }

    &:not(:last-child) {
      margin: 0;
    }
  }
}

@media screen and (min-width: 1760px) {
  .for-parents__content-box {
    &:nth-child(2) {
      .for-parents__img-box {
        left: -80px;
      }
    }
  }
}

@media screen and (min-width: 1936px) {
  .for-parents__content-box {
    &:nth-child(2) {
      .for-parents__img-box {
        left: 55px;
      }
    }
  }
}

@media screen and (min-width: 2390px) {
  .for-parents__content-box {
    &:nth-child(2) {
      .for-parents__img-box {
        left: 235px;
      }
    }
  }
}

@keyframes spinSun {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
