.bg-text {
  background-repeat: no-repeat;
  background-position: 0 18px;
  background-size: 0;
  transition: var(--transition);
  transition-duration: 2s;

  &.is-show {
    background-size: 100%;
  }

  &.bg-text-ios {
    background-position: 0 12px;
  }
}
