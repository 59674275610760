@import './base/fonts';
@import './utils/variables';
@import './utils/mixins';
@import './base/common';
@import './layout/header';
@import './components/common/book-btn';
@import './components/common/text-arrow';
@import './components/common/bg-text';
@import './components/common/modal-form';
@import './components/common/form';
@import './components/hero';
@import './components/info-camp';
@import './components/about';
@import './components/quote';
@import './components/goal';
@import './components/about-children';
@import './components/person-benefits';
@import './components/help-children';
@import './components/residence';
@import './components/for-parents';
@import './components/camp-benefits';
@import './components/reviews';
@import './components/price';
@import './components/callback';
@import './layout/footer';
@import './components/common/modal-info';
@import './pages/success';
