.section.info-camp {
  position: relative;
  height: 185px;
  background-color: var(--light-black);
  background-image: url(../images/info-camp/bg-top.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/info-camp/bg-top@2x.png);
  }

  .container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.info-camp__slider {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 20px;
  width: 100%;
  overflow: hidden;
}
.info-camp__list {
  display: flex;
  align-items: center;
}
.info-camp__list-item {
  width: 128px;
}
.info-camp__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  text-align: center;
  color: var(--white);

  .bold {
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
    color: var(--light-green);
  }

  .icon {
    width: 23px;
    height: 32px;
  }
}

@include for-size(mobile) {
  .info-camp__list {
    justify-content: center;
  }
}

@include for-size(tablet) {
  .section.info-camp {
    background-repeat: repeat-x;
    background-position: 0em top;
  }

  .info-camp__list {
    gap: 30px;
  }
}

@include for-size(desktop-s) {
  .section.info-camp {
    height: 304px;
    background-image: url(../images/info-camp/bg-top.png);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/info-camp/bg-top@2x.png);
    }
  }

  .info-camp__list {
    gap: 0;
  }

  .info-camp__list-item {
    width: 200px;

    &:not(:last-child) {
      border-right: 1px solid #5c5c5c;
    }
  }
}

@include for-size(desktop-m) {
  .section.info-camp {
    overflow: hidden;
  }

  .info-camp__slider {
    overflow: visible;
  }

  .info-camp__list {
    position: relative;
    bottom: -23px;
  }

  .info-camp__list-item {
    position: relative;
    width: 299px;

    &:not(:last-child) {
      border-right: none;

      &::before {
        content: '';
        position: absolute;
        top: 7px;
        right: 9px;
        display: block;
        height: 52px;
        width: 3px;
        background-color: #5c5c5c;
      }
    }
  }

  .info-camp__text {
    position: relative;
    left: -10px;
    gap: 0;
  }
}
