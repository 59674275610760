.section.help-children {
  position: relative;
  top: -3px;
  padding: 60px 0 90px 0;
  background-color: var(--light-black);
  background-image: url(../images/help-children/bg.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0em center;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/help-children/bg@2x.png);
  }
}
.help-children__content-box {
  &:nth-child(1) {
    .help-children__text {
      margin: 0 0 40px auto;
      max-width: 273px;
      line-height: 1.07;

      &.text-arrow.black::before {
        left: -25px;
        bottom: 0;
      }

      .bg-text {
        font-weight: 600;
        color: var(--black);
        background-image: linear-gradient(
          90deg,
          rgba(149, 255, 207, 1) 0%,
          rgba(149, 255, 207, 1) 50%,
          rgba(149, 255, 207, 1) 100%
        );
      }
    }
  }

  &:nth-child(2) {
    .help-children__text {
      margin-bottom: 40px;
      .bold {
        font-weight: 600;
        color: var(--black);
      }
    }
  }
}
.help-children__title {
  margin-bottom: 40px;
  max-width: 316px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.06;
  color: var(--black);
}
.help-children__image-box {
  position: relative;
  margin-bottom: 65px;
  width: 240px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -30px;
    right: -66px;
    display: block;
    width: 141px;
    height: 81px;
    background-image: url(../images/help-children/w.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/help-children/w@2x.png);
    }
  }
}
.help-children__list {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.help-children__list-item {
  padding: 19px 20px 31px 23px;
  width: 355px;
  border: 1px solid var(--white);
  border-radius: 10px;

  .title {
    margin-bottom: 7px;
    font-weight: 600;
    color: var(--black);
  }
  .text {
    font-size: 20px;
    line-height: 1.1;
  }
}

.help-children__cb-btn {
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 414px) {
  .help-children__image-box {
    width: 260px;

    &::after {
      bottom: -39px;
      right: -86px;
      width: 185px;
      height: 105px;
    }
  }
}

@include for-size(mobile) {
  .help-children__image-box {
    margin-bottom: 90px;
    width: 390px;

    &::after {
      bottom: -45px;
      right: -101px;
      width: 215px;
      height: 123px;
    }
  }

  .help-children__list {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .help-children__list-item {
    flex-basis: calc((100% - 30px * 1) / 2);
  }
}

@include for-size(tablet) {
  .section.help-children {
    padding: 60px 0 110px 0;
  }

  .help-children__content-box {
    &:nth-child(1) {
      .help-children__text {
        margin: 0 0 65px auto;
        max-width: 347px;
        font-size: 28px;
      }
    }

    &:nth-child(2) {
      .help-children__text {
        max-width: 642px;
      }
    }
  }

  .help-children__title {
    margin-bottom: 40px;
    max-width: 390px;
    font-size: 54px;
  }

  .help-children__image-box {
    margin-bottom: 110px;
    width: 418px;

    &::after {
      width: 279px;
      height: 160px;
      bottom: -59px;
      right: -130px;
    }
  }

  .help-children__list {
    margin-bottom: 50px;
  }
}

@include for-size(desktop-s) {
  .section.help-children {
    padding: 130px 0 90px;
  }

  .help-children__content-box {
    &:nth-child(1) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 130px;
      margin-bottom: 80px;

      .help-children__text {
        margin: 0 0 0 auto;
        max-width: 347px;
        font-size: 28px;
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 40px;

      .box {
        margin-top: 155px;
      }

      .help-children__text {
        margin-bottom: 60px;
      }
    }
  }

  .help-children__title {
    margin-bottom: 44px;
  }

  .help-children__image-box {
    margin: 0;
  }

  .help-children__list {
    width: 970px;
  }

  .help-children__list-item {
    flex-basis: calc((100% - 30px * 1) / 2);
  }
}

@include for-size(desktop-m) {
  .section.help-children {
    padding: 163px 0 35px;
  }

  .help-children__content-box {
    &:nth-child(1) {
      gap: 180px;
      margin-bottom: 80px;

      .help-children__text {
        margin: 0 0 0 100px;

        &.text-arrow.black::before {
          bottom: 6px;
          left: -29px;
        }
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 127px;

      .box {
        margin: 130px 0 0 100px;
        min-width: 357px;
      }

      .help-children__text {
        margin-bottom: 78px;
      }
    }
  }

  .help-children__title {
    margin-top: 16px;
    margin-bottom: 32px;
    max-width: 460px;
    font-size: 64px;
  }

  .help-children__image-box {
    &::after {
      width: 425px;
      height: 243px;
      bottom: -91px;
      right: -198px;
    }
  }

  .help-children__list {
    width: 970px;
    gap: 30px;
  }

  .help-children__cb-btn {
    margin: 0 0 0 56px;
  }
}
