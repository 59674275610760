.section.quote {
  position: relative;
  top: -3px;
  padding: 80px 0 67px 0;
  background-color: var(--light-green2);
  background-image: url(../images/quote/bg.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0em bottom;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/quote/bg@2x.png);
  }

  &.quote-2 {
    position: relative;
    top: -3px;
    padding: 60px 0 67px 0;
    background-color: var(--pink);
  }

  &.quote-3 {
    position: relative;
    top: -6px;
    padding: 70px 0 67px 0;
    background-color: #e0feff;
  }
}
.quote__main-text {
  max-width: 970px;
  margin: 0 auto 26px auto;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  color: var(--white);

  &.quote__main-text-2 {
    max-width: 850px;
  }
}
.quote__second-text {
  margin: 0 auto;
  max-width: 217px;
  line-height: 1.1;
  text-align: center;
  color: #7c7c7c;

  .bold {
    color: var(--white);
  }
}

@include for-size(tablet) {
  .section.quote {
    &.quote-2 {
      padding: 20px 0 67px;
    }
  }
}

@include for-size(desktop-s) {
  .section.quote {
    padding: 58px 0 113px 0;
    background-image: url(../images/quote/bg.png);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/quote/bg@2x.png);
    }

    &.quote-2 {
      padding: 84px 0 97px;
    }

    &.quote-3 {
      padding: 88px 0 93px 0;
    }
  }

  .quote__main-text {
    margin: 0 auto 36px auto;
    font-size: 48px;
  }

  .quote__second-text {
    max-width: 368px;
    font-size: 26px;
  }
}
