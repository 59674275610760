.box-form {
  padding: 40px 50px 20px 50px;
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
  height: 292px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 20px;
  -webkit-box-shadow: -2px 30px 34px -35px rgba(0, 0, 0, 1);
  -moz-box-shadow: -2px 30px 34px -35px rgba(0, 0, 0, 1);
  box-shadow: -2px 30px 34px -35px rgba(0, 0, 0, 1);
}

.main-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;

  .label {
    position: relative;
    width: 100%;
  }

  .label-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-weight: 600;
    line-height: 1.27;
    color: var(--black);
    transition: var(--transition);
    opacity: 1;

    &.is-hidden {
      opacity: 0;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: var(--transition);

    &.is-error {
      opacity: 1;
    }
  }
}

.main-form__input {
  padding: 0 20px 0 0;
  height: 39px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
  outline-color: transparent;
  border-radius: 0;
  outline: none;

  &.is-error {
    border-bottom: 1px solid red;
  }
}

.input-error {
  position: absolute;
  left: 0;
  bottom: -18px;
  width: 100%;
  font-size: 12px;
  color: red;
  opacity: 0;
  transition: var(--transition);

  &.is-error {
    opacity: 1;
  }
}

.submit-error {
  width: 240px;
  color: red;
  opacity: 0;
  transition: var(--transition);
  font-size: 14px;
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translateX(-50%);

  &.is-error {
    opacity: 1;
  }
}

.main-form__submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 23px;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: var(--black);
  background-color: var(--light-green);
  border: 1px solid var(--light-green);
  border-radius: 50px;
  transition: var(--transition);
  -webkit-box-shadow: 0px 20px 80px -15px #95ffcf;
  -moz-box-shadow: 0px 20px 80px -15px #95ffcf;
  box-shadow: 0px 20px 80px -15px #95ffcf;

  &:hover,
  &:focus {
    background-color: transparent;
  }
}

@include for-size(desktop-m) {
  .box-form {
    position: relative;
    padding: 0;
    max-width: unset;
    width: 570px;
    height: 392px;
    z-index: 5;

    &::before {
      content: '';
      position: absolute;
      top: 52%;
      right: -137px;
      transform: translateY(-50%);
      display: block;
      width: 287px;
      height: 295px;
      background-image: url(../images/icons/sun.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      z-index: -1;

      @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
        background-image: url(../images/icons/sun@2x.png);
      }
    }
  }

  .main-form {
    height: 100%;
    padding: 81px 101px 28px 101px;
    background-color: var(--white);
    border-radius: 20px;
  }

  .main-form__input {
    height: 46px;
  }
}
