.section.residence {
  position: relative;
  top: -4px;
  padding: 60px 0 150px 0;
  background-color: #ddfff1;
  background-image: url(../images/residence/bg.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0em center;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/residence/bg@2x.png);
  }
}
.residence__content-box {
  margin-bottom: 80px;
  color: var(--white);
}
.residence__title {
  margin-bottom: 50px;
  max-width: 300px;
  font-weight: 700;
  font-size: 54px;
  line-height: 1.03;
}
.residence__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  margin: 0 auto;
  max-width: 390px;
  width: 100%;
}
.residence__info-item {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}
.residence__info-title {
  flex-basis: calc((100% - 10px * 1) / 2);
  font-weight: 600;
  font-size: 28px;
  line-height: 0.79;
  color: var(--light-green);
}
.residence__info-text {
  flex-basis: calc((100% - 10px * 1) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7c7c7c;
  line-height: 1.1;

  .bold {
    font-weight: 600px;
    text-decoration: underline;
    color: var(--white);
  }
}
.residence__link-map {
  flex-basis: calc((100% - 10px * 1) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 16px;
  max-width: 175px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  color: var(--white);
  background-color: var(--purple);
  border: 1px solid var(--purple);
  border-radius: 50px;
  transition: var(--transition);
  -webkit-box-shadow: 0px 20px 80px -15px rgba(131, 138, 246, 1);
  -moz-box-shadow: 0px 20px 80px -15px rgba(131, 138, 246, 1);
  box-shadow: 0px 20px 80px -15px rgba(131, 138, 246, 1);

  &:hover,
  &:focus {
    background-color: transparent;
  }
}
.residence__gallery-title {
  margin: 0 0 30px auto;
  max-width: 350px;
  font-weight: 600;
  font-size: 38px;
  line-height: 1.04;
  text-align: right;
  color: var(--white);
}
.residence__gallery-count-list {
  display: none;
}
.residence__gallery-slider {
  padding: 70px 0 75px 0;

  .item {
    height: 412px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.residence__gallery-slider-paging {
  top: 20px;
  left: 0;
  bottom: unset;
  text-align: left;
  color: var(--white);

  .swiper-pagination-current {
    font-weight: 700;
    font-size: 44px;
    line-height: 0.8;
    color: var(--white);
  }
  .swiper-pagination-total {
    font-size: 20px;
  }
}

.residence__gallery-slider-btn-next,
.residence__gallery-slider-btn-prev {
  padding: 5px;
  min-width: 64px;
  min-height: 80px;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    .icon {
      stroke: var(--white);
    }
  }

  .icon {
    min-width: 64px;
    min-height: 80px;
    stroke: var(--main-text);
    transition: var(--transition);

    &.is-active {
      stroke: var(--white);
    }
  }

  &::after {
    content: '';
  }
}

.residence__gallery-slider-btn-next {
  top: 91%;
  left: 80%;
}
.residence__gallery-slider-btn-prev {
  top: 91%;
  left: 50%;
}

@media screen and (min-width: 414px) {
  .residence__link-map {
    position: relative;
    right: 20px;
  }
}

@include for-size(tablet) {
  .residence__title {
    margin-bottom: 65px;
    font-size: 64px;
  }

  .residence__info {
    flex-direction: row;
    align-items: flex-start;
    gap: 80px;
    max-width: unset;
    margin: 0;
  }

  .residence__info-item {
    display: block;

    &:nth-child(2) {
      .residence__info-title {
        text-align: center;
        margin-bottom: 25px;
      }
    }
  }

  .residence__info-title {
    margin-bottom: 14px;

    &.mobile {
      display: none;
    }
  }

  .residence__link-map {
    right: 0;
    margin: 0 auto;
  }

  .residence__gallery-slider {
    padding: 0 0 95px 0;
  }

  .residence__gallery-title {
    max-width: 370px;
    font-size: 48px;
  }

  .residence__gallery-count-list {
    display: flex;
    align-items: center;
    gap: 10px;

    .item {
      font-weight: 700;
      font-size: 20px;
      line-height: 2.6;

      &.is-active {
        color: var(--white);
      }
    }
  }

  .residence__gallery-slider-paging {
    top: unset;
    bottom: 0;

    .swiper-pagination-current {
      font-size: 64px;
    }
  }

  .residence__gallery-slider-btn-next,
  .residence__gallery-slider-btn-prev {
    z-index: 50;
  }

  .residence__gallery-slider-btn-next {
    left: 90%;
  }

  .residence__gallery-slider-btn-prev {
    left: 75%;
  }
}

@include for-size(desktop-s) {
  .section.residence {
    padding: 100px 0 200px 0;
    overflow: hidden;
  }

  .residence__content-box {
    display: flex;
    align-items: center;
    gap: 130px;
  }

  .residence__info {
    gap: 30px;
  }

  .residence__title {
    margin: 0;
  }

  .residence__info-item {
    width: auto;

    &:nth-child(1) {
      text-align: left;

      .residence__info-text {
        max-width: 170px;
        align-items: flex-end;
      }
    }
    &:nth-child(2) {
      .residence__info-title {
        margin-bottom: 35px;
        max-width: 164px;
      }
    }
    &:nth-child(3) {
      text-align: right;

      .residence__info-text {
        max-width: 170px;
        align-items: flex-end;
      }
    }
  }

  .residence__gallery-container {
    .box {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      margin-bottom: 40px;
    }
  }

  .residence__gallery {
    width: 1980px;
  }

  .residence__gallery-title {
    margin: 0 0 0 auto;
  }

  .residence__gallery-slider {
    .item {
      width: 370px;
      height: 412px;
    }
  }

  .residence__gallery-slider-paging {
    left: 0;
  }

  .residence__gallery-slider-btn-next {
    left: 45%;
  }
  .residence__gallery-slider-btn-prev {
    left: 39%;
  }
}

@include for-size(desktop-m) {
  .section.residence {
    padding: 136px 0 235px 0;
  }

  .residence__title {
    margin: 0 0 0 100px;
  }

  .residence__info-item {
    &:nth-child(2) {
      .residence__info-title {
        margin-bottom: 40px;
      }
    }
  }

  .residence__content-box {
    align-items: baseline;
    gap: 100px;
    margin-bottom: 64px;
  }

  .residence__gallery-container {
    .box {
      align-items: flex-end;
      margin-bottom: 55px;
    }
  }

  .residence__gallery-title {
    position: relative;
    top: 5px;
  }

  .residence__gallery-count-list {
    position: relative;
    top: 14px;
    right: 4px;
    gap: 14px;
  }

  .residence__gallery-slider {
    padding: 0 0 116px 0;
  }

  .residence__gallery-slider-btn-prev,
  .residence__gallery-slider-btn-next {
    min-width: 82px;
    min-height: 94px;

    .icon {
      min-width: 82px;
      min-height: 94px;
    }
  }

  .residence__gallery-slider-btn-prev {
    left: 46%;
    top: 89%;
  }

  .residence__gallery-slider-btn-next {
    left: 55%;
    top: 89%;
  }
}
