.section.about {
  padding: 80px 0 95px 0;
  background-color: var(--light-black);
  background-image: url(../images/about/bg-top@2x.png),
    url(../images/about/mobile/bg-bottom.svg), url(../images/about/bg.png);
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: center top, center bottom, 0em bottom;
  background-size: contain, contain, cover;
  transition: var(--transition-wave);
}
.about__box {
  &:nth-child(1) {
    margin-bottom: 62px;
  }
}
.about__img-box {
  width: 204px;
  margin-bottom: 52px;
  transition-duration: 2s;
}
.about__title {
  margin-bottom: 20px;
  max-width: 374px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.1;
  color: var(--black);

  &.second {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 32px;
    line-height: 1;
  }
}
.about__text {
  padding-left: 50px;
  max-width: 350px;
  font-size: 28px;
  font-weight: 400;
  line-height: 107%;

  .bg-text {
    font-weight: 600;
    color: var(--black);
    background-image: linear-gradient(
      90deg,
      rgba(149, 255, 207, 1) 0%,
      rgba(149, 255, 207, 1) 50%,
      rgba(149, 255, 207, 1) 100%
    );
  }

  &.second {
    max-width: unset;
    padding: 0;

    .bold {
      color: var(--black);
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
.about__list-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px 20px;
  margin: 0 auto 52px auto;
  max-width: 353px;
}
.about__list-images-item {
  height: 128px;
  width: 180px;

  &:nth-child(2),
  &:nth-child(3) {
    width: 118px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include for-size(mobile) {
  .section.about {
    padding: 80px 0 130px 0;
  }

  .about__img-box {
    width: 300px;
  }

  .about__text {
    margin-left: auto;
  }

  .about__list-images {
    max-width: unset;
    width: 560px;
  }

  .about__list-images-item {
    height: 200px;
    width: 310px;

    &:nth-child(2),
    &:nth-child(3) {
      width: 230px;
    }
  }

  .about__title {
    &.second {
      margin-bottom: 30px;

      .desktop {
        display: none;
      }
    }
  }

  .about__text {
    &.second {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

@include for-size(tablet) {
  .section.about {
    padding: 80px 0 150px 0;
    background-repeat: repeat-x, no-repeat, repeat-x;
    background-position: center top, center bottom, center bottom;
    background-size: contain, contain, cover;
  }

  .about__box {
    &:nth-child(1) {
      display: flex;
      justify-content: space-between;
    }
  }

  .about__list-images {
    width: 615px;
  }

  .about__text {
    font-size: 28px;

    &.second {
      margin-left: 0;
      max-width: 570px;
    }
  }
}

@include for-size(desktop-s) {
  .section.about {
    padding: 120px 0 190px 0;
    background-image: url(../images/about/bg-top.png),
      url(../images/about/mobile/bg-bottom.svg), url(../images/about/bg.png);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/about/bg-top@2x.png),
        url(../images/about/mobile/bg-bottom.svg),
        url(../images/about/bg@2x.png);
    }
  }

  .about__box {
    display: flex;
    &:nth-child(1) {
      margin-bottom: 116px;
    }
    &:nth-child(2) {
      flex-direction: row-reverse;
      gap: 30px;
    }
  }

  .about__img-box {
    margin: 0;
    width: 470px;
  }

  .about__title {
    max-width: 420px;
    margin-bottom: 30px;
    font-size: 52px;
  }

  .about__content-box,
  .about__list-images {
    width: 50%;
  }

  .about__list-images-item {
    height: 172px;
    width: 269px;

    &:nth-child(2),
    &:nth-child(3) {
      width: 170px;
    }
  }
}

@include for-size(desktop-m) {
  .section.about {
    padding: 192px 0 235px;
  }

  .about__box {
    &:nth-child(1) {
      gap: 130px;
      justify-content: flex-start;
    }
    &:nth-child(2) {
      justify-content: space-between;
    }
  }

  .about__list-images {
    width: 470px;
    height: 384px;
    margin: 34px 0 0 0;
    gap: 16px 30px;
  }

  .about__list-images-item {
    &:nth-child(2),
    &:nth-child(3) {
      width: 167px;
    }
  }

  .about__content-box {
    width: 475px;
  }

  .about__title {
    margin-top: 73px;
    margin-bottom: 34;
    max-width: 554px;
    font-size: 64px;
    line-height: 1.05;

    &.second {
      margin: -7px 0 37px 0;
      max-width: 470px;
      font-size: 48px;

      .desktop {
        display: block;
      }
    }
  }

  .about__text {
    padding: 0;
    margin-left: 100px;
    max-width: 310px;

    &.second {
      padding-left: 98px;
      font-size: 22px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
