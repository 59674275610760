.footer {
  padding-top: 50px;
  background-color: var(--light-black);

  .footer-flex-container {
    padding-bottom: 50px;
  }
}

.footer-flex-container,
.footer__contact-box,
.footer__socials-box,
.footer__nav-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-flex-container {
  gap: 40px;
}

.footer__contact-list {
  margin-bottom: 40px;
}

.footer__contact-list-item {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.footer__contact-link {
  font-size: 18px;
  line-height: 1.56;
  color: var(--white);
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--purple);
  }
}

.footer__cb-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  color: var(--white);
  background-color: transparent;
  border: 1px solid var(--light-green);
  border-radius: 50px;
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--black);
    background-color: var(--light-green);
  }
}

.footer__logo {
  margin-bottom: 60px;
  display: block;
  padding: 5px;
  min-width: 117px;
  min-height: 50px;

  .icon {
    width: 117px;
    height: 50px;
  }
}

.footer__link-email {
  margin-bottom: 33px;
  font-weight: 400;
  font-size: 26px;
  line-height: 0.78;
  color: var(--white);
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--purple);
  }
}

.footer__list-socials {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer__social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: var(--transition);
}

.footer__nav-list {
  margin-bottom: 50px;
}

.footer__nav-list-item {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.footer__nav-link {
  display: block;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--white);
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--purple);
  }
}
.copyright {
  font-size: 16px;
  line-height: 1.37;
  color: var(--white);
  opacity: 0.5;
}

.footer__info-box {
  padding: 27px 0 20px 0;
  background-color: #0e0e0e;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer__rules-list {
  margin-bottom: 30px;
}

.footer__rules-list-item {
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
.footer__rule-btn {
  padding: 8px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-align: center;
  color: #65666a;
  background-color: transparent;
  border: none;
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--white);
  }
}

.company-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

@include for-size(tablet) {
  .footer {
    padding-top: 70px;

    .footer-flex-container {
      padding-bottom: 70px;
    }
  }

  .footer-flex-container {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: center;
  }

  .footer__contact-list {
    margin-bottom: 85px;
  }

  .footer__nav-link {
    text-align: left;
  }

  .footer__info-box {
    .box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .footer__rules-list {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .footer__rules-list-item {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

@include for-size(desktop-s) {
  .footer-flex-container {
    justify-content: space-between;
  }

  .footer__nav-list {
    margin-bottom: 60px;
  }
}

@include for-size(desktop-m) {
  .footer {
    padding-top: 51px;

    .footer-flex-container {
      padding-bottom: 93px;
    }
  }

  .footer__contact-list {
    margin-bottom: 143px;
  }

  .footer__contact-list-item {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .footer__contact-link {
    font-size: 19px;
  }

  .footer__logo {
    margin-bottom: 68px;
  }

  .footer__link-email {
    margin-bottom: 47px;
    font-size: 36px;
  }

  .footer__list-socials {
    gap: 10px;
  }

  .footer__nav-box {
    margin-right: 36px;
  }

  .footer__nav-list {
    margin-bottom: 132px;
  }

  .footer__nav-list-item {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .footer__rules-list {
    position: relative;
    bottom: 8px;
    gap: 10px;
  }

  .footer__rule-btn {
    font-size: 13px;
  }

  .company-link {
    position: relative;
    bottom: 11px;
    right: 25px;
  }
}
