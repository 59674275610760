.section.about-children {
  position: relative;
  top: -3px;
  padding: 60px 0;
  background-color: var(--light-green2);
  background-image: url(../images/about-children/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/about-children/bg@2x.png);
  }
}
.about-children__content-box {
  &:nth-child(1) {
    margin-bottom: 30px;

    .about-children__img-box {
      position: relative;
      margin-bottom: 46px;
      width: 230px;
      height: 272px;

      &::after {
        content: '';
        position: absolute;
        top: -58px;
        right: -104px;
        display: block;
        width: 208px;
        height: 119px;
        background-image: url(../images/about-children/w.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
          background-image: url(../images/about-children/w@2x.png);
        }
      }
    }
  }

  &:nth-child(2) {
    margin-bottom: 64px;

    .about-children__text {
      &.first {
        margin-bottom: 58px;
        max-width: 315px;
        font-size: 28px;
        line-height: 1.1;
      }
      &.second {
        .bold {
          font-weight: 600;
          color: var(--black);
        }
      }

      .bg-text {
        color: var(--black);
        background-image: linear-gradient(
          90deg,
          rgba(197, 221, 247, 1) 0%,
          rgba(197, 221, 247, 1) 50%,
          rgba(197, 221, 247, 1) 100%
        );
      }
    }

    .about-children__img-box {
      margin: 0 0 46px auto;
      width: 298px;
      height: 192px;
    }
  }
}

.about-children__title {
  margin-left: auto;
  max-width: 376px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.05;
  text-align: right;
  color: var(--black);
}

.about-children__img-box {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-children__img-list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 53px;
}
.about-children__img-list-item {
  flex-basis: calc((100% - 20px * 1) / 2);
}
.about-children__benefits-list-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.icon-box {
  position: relative;
  min-height: 75px;
  min-width: 75px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    min-height: 75px;
    min-width: 75px;
    background-image: url(../images/icons/camp-circle.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    animation-name: spin;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/icons/camp-circle@2x.png);
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 40px;
  }
}

.overtitle {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 28px;
  line-height: 0.79;
  color: var(--black);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 390px) {
  .about-children__content-box {
    &:nth-child(1) {
      .about-children__img-box {
        width: 248px;
      }
    }
  }
}

@media screen and (min-width: 414px) {
  .about-children__content-box {
    &:nth-child(1) {
      .about-children__img-box {
        width: 278px;
      }
    }
  }
}

@include for-size(mobile) {
  .about-children__content-box {
    &:nth-child(1) {
      .about-children__img-box {
        width: 350px;
        height: 320px;
      }
    }

    &:nth-child(2) {
      .about-children__text {
        &.first {
          max-width: 340px;
        }
      }

      .about-children__img-box {
        width: 340px;
        height: 225px;
      }
    }
  }

  .about-children__benefits-list-item {
    gap: 30px;
  }
}

@include for-size(tablet) {
  .about-children__content-box {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      margin-bottom: 120px;

      .about-children__img-box {
        margin: 0;
      }
    }

    &:nth-child(2) {
      position: relative;

      .about-children__img-box {
        position: absolute;
        top: -50px;
        right: 0;
        margin: 0;
      }

      .about-children__text {
        &.first {
          margin-bottom: 70px;
        }
        &.second {
          max-width: 80%;
        }
      }
    }
  }

  .about-children__img-list {
    gap: 40px;
  }

  .about-children__benefits-list {
    padding-left: 60px;
  }
}

@include for-size(desktop-s) {
  .section.about-children {
    padding: 134px 0;
    overflow: hidden;
  }

  .about-children__content-box {
    &:nth-child(1) {
      .about-children__img-box {
        width: 470px;
        height: 460px;

        &::after {
          top: -119px;
          right: -212px;
          width: 425px;
          height: 243px;
        }
      }
    }

    &:nth-child(2) {
      display: flex;
      margin-bottom: 120px;

      .about-children__img-box {
        top: -35px;
        right: -60px;
        width: 390px;
        height: 260px;
      }

      .about-children__text {
        &.first {
          margin: 0;
          max-width: 275px;
        }
        &.second {
          max-width: 300px;
        }
      }
    }

    &:nth-child(3) {
      display: flex;
      align-items: flex-start;
      gap: 40px;
    }
  }

  .about-children__title {
    max-width: 430px;
    font-size: 51px;
  }

  .about-children__img-list {
    margin: 0;
  }

  .about-children__benefits-list {
    padding: 0;
  }
}

@include for-size(desktop-m) {
  .about-children__content-box {
    &:nth-child(1) {
      margin-bottom: 152px;
    }

    &:nth-child(2) {
      gap: 30px;
      margin-bottom: 213px;

      .about-children__img-box {
        top: -68px;
        right: -375px;
        width: 775px;
        height: 500px;
      }

      .about-children__text {
        &.first {
          max-width: 369px;
          font-size: 28px;
        }
        &.second {
          max-width: 300px;
        }
      }
    }

    &:nth-child(3) {
      gap: 125px;
    }
  }

  .about-children__title {
    max-width: 540px;
    font-size: 64px;
  }

  .about-children__img-list {
    gap: 30px;
  }

  .about-children__img-list-item {
    flex-basis: unset;
    width: 270px;
    height: 336px;
  }

  .about-children__benefits-list-item {
    .subtitle {
      max-width: 310px;
    }
  }
}
