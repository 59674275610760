:root {
  --font-family: 'Proba Pro', sans-serif;
  --second-family: 'Gilroy', sans-serif;
  --black: #000000;
  --light-black: #2a2a2a;
  --white: #ffffff;
  --light-green: #95ffcf;
  --light-green2: #dbffef;
  // --main-text: #7c7c7c;
  --main-text: #424242;
  --second-text: #2e2e2e;
  --gray: #d9d9d9;
  --blue: #b0bcff;
  --pink: #f7ceff;
  --purple: #838af6;
  --transition: all 600ms ease;
  --transition-wave: all 800ms ease;
}
