.text-arrow {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 5px;
    display: block;
    width: 11px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &.green::before {
    left: -25px;
    background-image: url(../images/icons/text-icon-green.svg);
  }

  &.black::before {
    left: 23px;
    background-image: url(../images/icons/text-icon-black.svg);
  }

  &.purple::before {
    left: -30px;
    bottom: 0;
    background-image: url(../images/icons/text-icon-purple.svg);
  }

  &.blue::before {
    left: 15px;
    background-image: url(../images/icons/text-icon-blue.svg);
  }
}

@include for-size(tablet) {
  .text-arrow {
    &.purple::before {
      bottom: 40px;
    }
  }
}

@include for-size(desktop-s) {
  .text-arrow {
    &.purple::before {
      bottom: 55px;
    }
  }
}

@include for-size(desktop-m) {
  .text-arrow {
    &.green::before {
      bottom: 3px;
      left: -31px;
    }

    &.black::before {
      left: -32px;
    }

    &.purple::before {
      bottom: 13px;
    }

    &.blue::before {
      left: 0;
      background-image: url(../images/icons/text-icon-blue.svg);
    }
  }
}
