.section.price {
  position: relative;
  top: -8px;
  padding: 60px 0 0 0;
  background-color: var(--blue);
  background-image: url(../images/price/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/price/bg@2x.png);
  }
}
.price__content-box {
  margin-bottom: 80px;
}
.price__title {
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.42;
  color: var(--black);
}
.price__subtitle {
  max-width: 380px;
  padding-left: 50px;
  margin: 0 0 21px auto;
  font-size: 28px;
  line-height: 107%;

  .bg-text {
    font-weight: 600;
    color: var(--black);
    background-image: linear-gradient(
      90deg,
      rgba(176, 174, 250, 1) 0%,
      rgba(176, 174, 250, 1) 50%,
      rgba(176, 174, 250, 1) 100%
    );
  }
}
.price__text {
  max-width: 320px;
}
.price__list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.price__list-item {
  padding: 25px 10px 160px 45px;
  width: 270px;
  height: 377px;
  background-image: url(../images/price/bg-price.png);
  background-repeat: no-repeat;
  background-size: 325px 414px;
  background-position: center top;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/price/bg-price@2x.png);
  }

  &:nth-child(1) {
    .text {
      margin-bottom: 65px;
    }
  }

  &:nth-child(2) {
    .text {
      margin-bottom: 40px;
      max-width: 150px;
    }
  }

  .text {
    margin-bottom: 50px;
    max-width: 145px;

    .bg-text {
      font-weight: 600;
      color: var(--white);
      background-image: linear-gradient(
        90deg,
        rgba(86, 90, 144, 1) 0%,
        rgba(86, 90, 144, 1) 50%,
        rgba(86, 90, 144, 1) 100%
      );
    }

    .info {
      font-weight: 400;
      color: #838af6;
    }
  }

  .price-value {
    font-weight: 700;
    font-size: 48px;
    line-height: 1, 04;
    color: var(--black);

    .currency {
      font-weight: 400;
      font-size: 16px;
    }
  }
}

@include for-size(mobile) {
  .price__subtitle {
    margin: 0 0 21px 80px;
  }

  .price__list {
    flex-direction: row;
    justify-content: center;
  }
}

@include for-size(tablet) {
  .price__list {
    gap: 80px;
  }
}

@include for-size(desktop-s) {
  .section.price {
    padding: 100px 0 100px 0;
    .desktop-flex {
      display: flex;
    }
  }

  .price__subtitle {
    margin: 0 0 21px 70px;
  }

  .price__list {
    gap: 0;
  }
}

@include for-size(desktop-m) {
  .section.price {
    padding: 188px 0 0px 0;

    .desktop-flex {
      padding-left: 100px;
      gap: 60px;
    }
  }

  .price__title {
    margin-bottom: 17px;
  }

  .price__subtitle {
    margin: 0 0 16px 0;
    padding-left: 28px;
    max-width: 325px;
  }

  .price__list {
    position: relative;
    top: 19px;
  }

  .price__list-item {
    padding: 32px 10px 160px 49px;
    width: 298px;
    height: 430px;
    background-size: 364px 446px;

    &:nth-child(1) {
      .text {
        max-width: 185px;
      }
    }
    &:nth-child(2) {
      .text {
        margin-bottom: 69px;
        max-width: 195px;
      }
    }

    .text {
      margin-bottom: 69px;
      font-size: 28px;
      line-height: 1;
    }
  }
}
