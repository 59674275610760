$breakpoints: (
  mobile: 600px,
  tablet: 768px,
  desktop-s: 992px,
  desktop-m: 1200px,
);

@mixin for-size($range) {
  $mobile: map-get($breakpoints, mobile);
  $tablet: map-get($breakpoints, tablet);
  $desktop-s: map-get($breakpoints, desktop-s);
  $desktop-m: map-get($breakpoints, desktop-m);

  @if $range == mobile-only {
    @media screen and (max-width: #{$tablet - 1}) {
      @content;
    }
  } @else if $range == mobile {
    @media screen and (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet {
    @media screen and (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop-s {
    @media screen and (min-width: $desktop-s) {
      @content;
    }
  } @else if $range == desktop-m {
    @media screen and (min-width: $desktop-m) {
      @content;
    }
  }
}
