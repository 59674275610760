.section.hero {
  padding: 65px 0 44px 0;
  background-color: var(--blue);
  overflow: hidden;

  .container {
    position: relative;
  }
}
.hero__overtitle {
  max-width: 130px;
  margin: 0 0 24px 120px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  text-decoration: underline;
  color: var(--black);
}
.hero__main-title {
  max-width: 345px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 74px;
  line-height: 0.9;
  color: var(--white);
}
.hero__image-box {
  position: relative;
  height: 340px;
  width: 100%;
  max-width: 353px;
  margin: 0 auto 45px auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.hero__down-btn {
  position: fixed;
  top: 30%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  transition: var(--transition);
  transition-duration: 1.5s;
  z-index: 60;

  &.is-fixed {
    top: 5%;
    transition-duration: 1.5s;
  }

  .icon-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--purple);
    transition: var(--transition);
    opacity: 0;

    &.is-end {
      opacity: 1;
    }
  }

  .icon {
    width: 120px;
    height: 130px;
    opacity: 1;

    &.is-hidden {
      transition: var(--transition);
      opacity: 0;
    }
  }

  .icon-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 17px;
    height: 22px;
    z-index: 2;

    &.is-rotate {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

.hero__subtitle {
  max-width: 260px;
  margin-left: auto;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.1;
  color: var(--second-text);

  .bg-text {
    font-weight: 400;
    background-image: linear-gradient(
      90deg,
      rgba(149, 255, 207, 1) 0%,
      rgba(149, 255, 207, 1) 50%,
      rgba(149, 255, 207, 1) 100%
    );
  }
}
.hero__cb-btn {
  position: absolute;
  bottom: -40px;
  left: -10px;

  &.desktop {
    display: none;
  }
}

@include for-size(mobile) {
  .hero__cb-btn {
    bottom: -70px;
    left: -65px;
    width: 150px;
    height: 150px;
    font-size: 14px;
  }
}

@include for-size(tablet) {
  .hero__main-title {
    margin-left: 40px;
    margin-bottom: 40px;
  }

  .hero__image-box {
    max-width: 430px;
    height: 415px;
  }

  .hero__down-btn {
    .icon {
      width: 150px;
      height: 160px;
    }
  }

  .hero__subtitle {
    margin-right: 100px;
  }
}

@include for-size(desktop-s) {
  .section.hero {
    padding: 135px 0 53px 0;
    overflow: visible;
  }

  .hero__desktop-box {
    display: flex;
    align-items: flex-start;
    gap: 40px;
  }
  .hero__overtitle {
    margin: 0 0 24px 310px;
    font-size: 16px;
  }

  .hero__main-title {
    max-width: unset;
    margin: 0;
    font-size: 100px;
  }

  .hero__image-box {
    margin: 0 20px 0 0;
  }

  .hero__down-btn {
    top: 35%;

    .icon {
      width: 173px;
      height: 186px;
    }
  }

  .hero__subtitle {
    position: absolute;
    bottom: -25px;
    left: 255px;
    margin: 0;
  }

  .hero__cb-btn {
    bottom: -50px;
    left: 40px;
    font-size: 12px;
    z-index: 10;

    &.desktop {
      display: flex;
    }
    &.mobile {
      display: none;
    }
  }
}

@include for-size(desktop-m) {
  .hero__overtitle {
    margin: 0 0 4px 401px;
    max-width: 95px;
  }

  .hero__main-title {
    font-size: 130px;
  }

  .hero__image-box {
    margin: 0;
  }

  .hero__subtitle {
    bottom: 53px;
    left: 415px;
    line-height: 1;
  }

  .hero__cb-btn {
    bottom: -106px;
    left: 204px;
  }
}
