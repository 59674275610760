.section.success {
  padding: 118px 0 100px 0;
  min-height: 100vh;
  background-color: #b0bcff;
}

.success__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 778px;
  width: 100%;
  min-height: 100vh;
}

.success__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
  max-width: 111px;
  max-height: 36px;

  .icon {
    min-width: 111px;
    min-height: 36px;
  }
}

.success__title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 90px;
  line-height: 0.9;
  color: var(--white);
}

.success__text {
  margin: 0 0 60px auto;
  width: 222px;
  font-size: 26px;
  line-height: 1;
  color: var(--second-text);
}

.success__back-btn {
  padding: 27px 20px 19px 20px;
}

@include for-size(mobile) {
  .success__title {
    margin-bottom: 50px;
    font-size: 100px;
  }

  .success__text {
    margin: 0 0 60px 215px;
  }
}
