@font-face {
  font-family: 'Proba Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/ProbaPro-Regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/ProbaPro-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/ProbaPro-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/ProbaPro-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/ProbaPro-Regular.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proba Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/ProbaPro-SemiBold.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/ProbaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/ProbaPro-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/ProbaPro-SemiBold.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/ProbaPro-SemiBold.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proba Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/ProbaPro-Bold.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/ProbaPro-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/ProbaPro-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/ProbaPro-Bold.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/ProbaPro-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Gilroy-Light.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gilroy-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gilroy-Light.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/Gilroy-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Gilroy-Black.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gilroy-Black.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gilroy-Black.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/Gilroy-Black.ttf') format('truetype');
  /* Safari, Android, iOS */
}
