.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin: 0 15px;
  height: 400px;
  max-width: 550px;
  width: 100%;
  background-color: var(--white);
  border-radius: 20px;
}

.modal__title {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  color: var(--black);
  text-align: center;
}

.modal__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: transparent;
  border: none;

  .icon {
    width: 32px;
    height: 32px;
    fill: var(--black);
  }
}

@include for-size(tablet) {
  .modal {
    height: 445px;
  }
}

@include for-size(desktop-m) {
  .main-form.modal-form {
    padding: 0;
  }

  .modal {
    padding: 90px 60px 50px 60px;
  }
}
