.section.camp-benefits {
  position: relative;
  top: -8px;
  padding: 80px 0 120px 0;
  background-color: var(--light-black);
  background-image: url(../images/camp-benefits/bg.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0em center;
  transition: var(--transition-wave);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/camp-benefits/bg@2x.png);
  }
}

.camp-benefits__list {
  &.first {
    margin-bottom: 40px;
  }
}

.camp-benefits__list-item {
  .title {
    margin-bottom: 13px;
    font-weight: 600;
    font-size: 24px;
    line-height: 0.86;
    color: var(--black);
  }
  .text {
    max-width: 530px;
    font-size: 20px;
    line-height: 1.09;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.camp-benefits__img-box {
  margin: 0 auto 40px auto;
  max-width: 430px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include for-size(desktop-s) {
  .section.camp-benefits {
    padding: 129px 0 114px;
  }

  .section.camp-benefits {
    .desktop-flex {
      display: flex;
      gap: 30px;
    }
  }

  .camp-benefits__list {
    &.first {
      margin-bottom: 0;
    }
  }

  .camp-benefits__list-item {
    flex-basis: calc((100% - 30px * 1) / 2);
    .text {
      max-width: unset;
    }
  }

  .camp-benefits__img-box {
    max-width: unset;
    min-width: 430px;
    height: 440px;
  }
}

@include for-size(desktop-m) {
  .section.camp-benefits {
    padding: 169px 0 154px;
  }

  .section.camp-benefits {
    .desktop-flex {
      display: flex;
      gap: 50px;
    }
  }

  .camp-benefits__list {
    position: relative;
    bottom: 5px;

    &.second {
      text-align: right;

      .title,
      .text {
        margin-left: auto;
      }
    }
  }

  .camp-benefits__list-item {
    flex-basis: unset;

    .title {
      max-width: 268px;
      font-size: 28px;
    }

    .text {
      font-size: 22px;
      max-width: 300px;
    }

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }

  .camp-benefits__img-box {
    max-width: unset;
    min-width: 430px;
    height: 440px;
  }
}
