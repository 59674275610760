@import '~node_modules/modern-normalize/modern-normalize.css';
@import '~node_modules/swiper/swiper-bundle.min.css';
@import '~node_modules/glightbox/dist/css/glightbox.min.css';
@import '~node_modules/aos/dist/aos.css';
@import '~node_modules/animate.css/animate.min.css';

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  color: var(--main-text);
  background-color: var(--light-black);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

a,
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

button {
  cursor: pointer;
}

.container {
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @include for-size(mobile) {
    max-width: 600px;
  }

  @include for-size(tablet) {
    max-width: 768px;
  }

  @include for-size(desktop-s) {
    max-width: 992px;
  }

  @include for-size(desktop-m) {
    max-width: 1200px;
  }
}

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ad;
  z-index: 60;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  transition-duration: 400ms;

  &.is-show {
    opacity: 1;
    pointer-events: all;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  transition: all 250ms ease;
}
