.section.goal {
  position: relative;
  top: -3px;
  padding: 74px 0 69px 0;
  background-color: var(--light-green);
  background-image: url(../images/goal/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/goal/bg@2x.png);
  }
}
.goal__title {
  max-width: 353px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.05;
  color: var(--black);
}
.goal__subtitle {
  margin-left: auto;
  max-width: 260px;
  line-height: 1.07;

  .bg-text {
    font-weight: 600;
    color: var(--black);
    background-image: linear-gradient(
      90deg,
      rgba(197, 221, 247, 1) 0%,
      rgba(197, 221, 247, 1) 50%,
      rgba(197, 221, 247, 1) 100%
    );
  }
}

@include for-size(tablet) {
  .goal__content-box {
    display: flex;
    gap: 100px;
  }

  .goal__title,
  .goal__subtitle {
    margin: 0;
  }
}

@include for-size(desktop-s) {
  .section.goal {
    padding: 156px 0 145px 0;
  }

  .goal__title {
    max-width: 580px;
    font-size: 64px;
  }
}

@include for-size(desktop-m) {
  .section.goal {
    padding: 201px 0 151px 0;
  }

  .goal__content-box {
    display: flex;
    gap: 120px;
  }

  .goal__subtitle {
    margin-top: 17px;
    max-width: 368px;
    font-size: 28px;
  }
}
