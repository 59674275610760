.section.person-benefits {
  position: relative;
  top: -3px;
  padding: 60px 0 200px 0;
  background-color: var(--light-black);
  background-image: url(../images/person-benefits/mobile/picture.svg),
    url(../images/person-benefits/mobile/bg.png);
  background-repeat: no-repeat;
  background-size: contain, cover;
  background-position: center bottom;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: url(../images/person-benefits/mobile/picture.svg),
      url(../images/person-benefits/mobile/bg@2x.png);
  }
}
.person-benefits__title {
  margin: 0 0 30px auto;
  max-width: 340px;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.06;
  text-align: right;
  color: var(--black);
}
.person-benefits__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.person-benefits__list-item {
  position: relative;
  padding: 40px 28px 20px 28px;
  width: 350px;
  height: 220px;
  border: 1px solid var(--black);
  border-radius: 10px;
  z-index: 2;

  .background {
    position: absolute;
    top: -1px;
    right: -1px;
    display: block;
    width: 350px;
    height: 220px;
    border: 1px solid var(--black);
    border-radius: 10px;
    z-index: 1;
    background-color: #e8ecff;
    transition: var(--transition);
    transition-duration: 1s;

    &.is-effect {
      top: 10px;
      right: 10px;
    }
  }

  .list {
    position: relative;
    list-style: disc;
    color: #e78effed;
    z-index: 3;

    .item {
      span {
        color: var(--main-text);
      }
    }
  }
}
.person-benefits__list-title {
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 28px;
  line-height: 0.79;
  color: var(--black);
  z-index: 3;
}

@include for-size(mobile) {
  .person-benefits__list-item {
    width: 370px;

    .background {
      width: 370px;
    }
  }
}

@include for-size(tablet) {
  .section.person-benefits {
    background-image: url(../images/person-benefits/tablet/bg.png);
    background-size: cover;

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/person-benefits/tablet/bg@2x.png);
    }
  }

  .person-benefits__title {
    max-width: 450px;
    margin: 0 0 50px auto;
  }

  .person-benefits__list {
    margin: 0 auto;
    width: 570px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .person-benefits__list-item {
    flex-basis: calc((100% - 30px * 1) / 2);

    .background {
      width: 270px;
    }
  }
}

@include for-size(desktop-s) {
  .section.person-benefits {
    background-image: url(../images/person-benefits/desktop/bg.png);
    background-size: cover;

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/person-benefits/desktop/bg@2x.png);
    }
  }

  .person-benefits__title {
    margin: 0 0 70px auto;
    max-width: 640px;
    font-size: 64px;
  }

  .person-benefits__list {
    margin: 0 0 0 auto;
  }

  .person-benefits__list-item {
    .background {
      top: 3px;
      right: 3px;
      backdrop-filter: blur(30px);
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

@include for-size(desktop-m) {
  .section.person-benefits {
    padding: 133px 0 270px;
  }

  .person-benefits__box {
    display: flex;
  }

  .person-benefits__title {
    margin: 0;
    max-width: 500px;
    text-align: left;
  }
}
