.modal-info {
  position: relative;
  padding: 65px 10px 35px 25px;
  margin: 0 15px;
  width: 100%;
  max-width: 950px;
  border-radius: 6px;
  background-color: var(--white);
}

.modal-info__box {
  display: none;

  &.is-show {
    display: block;
  }
}

.modal-info__content {
  padding-right: 10px;
  max-height: 400px;
  overflow-y: scroll;

  b {
    display: inline-block;
    color: var(--black);
  }

  p,
  b {
    margin-bottom: 20px;
  }
}

.modal-info__title {
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
  color: black;
}
